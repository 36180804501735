export const themePresetNum = 0;

export const primaryColorList: string[] = [
  "#0960bd",
  "#0084f4",
  "#009688",
  "#536dfe",
  "#ff5c93",
  "#ee4f12",
  "#0096c7",
  "#9c27b0",
  "#ff9800",
];

export const headBgColorList: string[] = [
  "#ffffff",
  "#151515",
  "#009688",
  "#5172DC",
  "#018ffb",
  "#409eff",
  "#e74c3c",
  "#24292e",
  "#394664",
  "#001529",
  "#383f45",
];

export const sidebarBgColorList: string[] = [
  "#001529",
  "#212121",
  "#273352",
  "#ffffff",
  "#191b24",
  "#191a23",
  "#304156",
  "#001628",
  "#28333E",
  "#344058",
  "#383f45",
];
