import { IFlowItem } from "/@/views/automation/types";
import { IPage } from "/@/views/pages/types";
import { IFields } from "/@/views/settings/fields/types";
import { ITags } from "/@/views/settings/tags/types";
import { widgetsList, WidgetsType } from "/@/views/widgets/types";

export enum FlowSocketType {
  NEXT_STEP = "next_step",
}

export enum FlowCompType {
  TRIGGER = "trigger",
  SEND_MESSAGE = "send_message",
  ACTION = "action",
  CONDITION = "condition",
  GOTO = "goto",
  RANDOM = "random",
  SMART_DELAY = "smart_delay",
  AI_CONDITION = "ai_condition",
}

//  添加新节点时，需要到下面这个文件中的 copyFn 处理下 copy 时的 id 转换
// @/views/automation/flows/edit/plugins/node-copy/index.js
export const FlowCompObj = {
  [FlowCompType.TRIGGER]: {
    type: FlowCompType.TRIGGER,
    title: "触发器",
    icon: "ant-design:play-circle-outlined",
    //  禁止手动创建
    allowCreateManually: false,
  },
  [FlowCompType.SEND_MESSAGE]: {
    type: FlowCompType.SEND_MESSAGE,
    title: "发送消息",
    icon: widgetsList[WidgetsType.COMMENT].icon,
  },
  [FlowCompType.CONDITION]: {
    type: FlowCompType.CONDITION,
    title: "条件",
    icon: "carbon-operation-if",
  },
  [FlowCompType.ACTION]: {
    type: FlowCompType.ACTION,
    title: "执行",
    icon: "ant-design:thunderbolt-outlined",
  },
  [FlowCompType.GOTO]: {
    type: FlowCompType.GOTO,
    title: "运行Flow",
    icon: "ic-outline-exit-to-app",
  },
  [FlowCompType.RANDOM]: {
    type: FlowCompType.RANDOM,
    title: "随机节点",
    icon: "typcn-flow-children",
  },
  [FlowCompType.SMART_DELAY]: {
    type: FlowCompType.SMART_DELAY,
    title: "延时节点",
    icon: "bx:bx-time-five",
  },
  [FlowCompType.AI_CONDITION]: {
    type: FlowCompType.AI_CONDITION,
    title: "AI 条件节点",
    icon: "tabler:ai",
  },
};

export enum FlowRouterNamesEnum {
  //  正常的预览
  REVIEW = "FlowsEditReview",
  //  正常的编辑
  EDIT = "FlowsEdit",
  //  分享后的工作流
  SHARE_PREVIEW = "FlowsEditSharePreview",
  //  默认回复预览
  DEFAULT_REVIEW = "defaultReplyFlowReview",
  //  默认回复编辑
  DEFAULT_EDIT = "defaultReplyFlowEdit",
  //  欢迎消息预览
  WELCOME_REVIEW = "welcomeMessageReview",
  //  欢迎消息编辑
  WELCOME_EDIT = "welcomeMessageEdit",
}

export type TSharePreviewData = {
  available_fields: {
    custom_field: IFields[];
    system_field: IFields[];
  };
  flow: IFlowItem;
  page: IPage;
  tags: ITags[];
};
