const toString = Object.prototype.toString;

export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`;
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== "undefined";
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val);
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, "Object");
}

export function isEmpty<T = unknown>(val: T): val is T {
  if (isArray(val) || isString(val)) {
    return val.length === 0;
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0;
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0;
  }

  return false;
}

export function isDate(val: unknown): val is Date {
  return is(val, "Date");
}

export function isNull(val: unknown): val is null {
  return val === null;
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) && isNull(val);
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) || isNull(val);
}

export function isNumber(val: unknown): val is number {
  return is(val, "Number");
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
  return is(val, "Promise") && isObject(val) && isFunction(val.then) && isFunction(val.catch);
}

export function isString(val: unknown): val is string {
  return is(val, "String");
}

export function isFunction(val: unknown): val is Function {
  return typeof val === "function";
}

export function isBoolean(val: unknown): val is boolean {
  return is(val, "Boolean");
}

export function isRegExp(val: unknown): val is RegExp {
  return is(val, "RegExp");
}

export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val);
}

export function isWindow(val: any): val is Window {
  return typeof window !== "undefined" && is(val, "Window");
}

export function isElement(val: unknown): val is Element {
  return isObject(val) && !!val.tagName;
}

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

export function isUrl(path: string): boolean {
  const reg =
    /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
  return reg.test(path);
}


/**
 * 深度对比两个对象的指定键值是否相同
 * @param obj1 第一个对象
 * @param obj2 第二个对象
 * @param keys 需要对比的键数组
 * @returns 如果指定的所有键值都相同则返回 true，否则返回 false
 */
export function isDeepEqual<T extends Record<string, any>, K extends keyof T>(
  obj1: T,
  obj2: T,
  keys: K[]
): boolean {
  // 如果对象引用相同，直接返回 true
  if (obj1 === obj2) {
    return true;
  }
  
  // 如果任一对象为空，返回 false
  if (!obj1 || !obj2) {
    return false;
  }

  // 遍历需要比较的键
  for (const key of keys) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    
    // 如果值相同，继续下一个键的比较
    if (val1 === val2) {
      continue;
    }
    
    // 如果值类型不同，返回 false
    if (typeof val1 !== typeof val2) {
      return false;
    }
    
    // 处理数组类型
    if (isArray(val1) && isArray(val2)) {
      // 长度不同，返回 false
      if (val1.length !== val2.length) {
        return false;
      }
      
      // 递归比较数组中的每个元素
      for (let i = 0; i < val1.length; i++) {
        if (isObject(val1[i]) && isObject(val2[i])) {
          // 如果是对象，递归比较所有键
          if (!isDeepEqual(val1[i], val2[i], Object.keys(val1[i]) as any)) {
            return false;
          }
        } else if (val1[i] !== val2[i]) {
          return false;
        }
      }
    } 
    // 处理对象类型
    else if (isObject(val1) && isObject(val2)) {
      // 获取两个对象的所有键
      const objKeys1 = Object.keys(val1);
      const objKeys2 = Object.keys(val2);
      
      // 键的数量不同，返回 false
      if (objKeys1.length !== objKeys2.length) {
        return false;
      }
      
      // 递归比较对象的所有键值
      if (!isDeepEqual(val1, val2, objKeys1 as any)) {
        return false;
      }
    } 
    // 其他类型直接比较值
    else if (val1 !== val2) {
      return false;
    }
  }
  
  return true;
}
